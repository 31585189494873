import React, { useEffect, useState } from 'react';
import AdminContainer from '../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../contexts/breadcrumbs-provider';
import { getApiRequest } from '../../../../utils/request';
import Loadable from '../../../data/loadable';
import Heading from '../../../layout/admin/heading';
import StatsBar from './common/stats-bar';
import SEO from '../../../layout/seo';

const AdminDashboard = () => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setLoading(true);
    setBreadcrumbs([{
      href: '/app/admin/dashboard',
      label: 'Dashboard',
    }]);

    const init = async () => {
      try {
        const result = await getApiRequest(`/admin/stats/dashboard`);
        setStats(result);
      } catch (err) {
        console.log(err, 'error loading stats');
      }
      setLoading(false);
    }
    
    init();
  }, []);

  return (
    <AdminContainer>
      <SEO title="Dashboard" />
      <Heading title="Dashboard" />
      <Loadable loading={loading}>
        <div className="max-w-5xl p-4 md:px-8 mx-auto">
          <StatsBar title="Last 7 days" stats={stats} />
        </div>
      </Loadable>
    </AdminContainer>
  );
}

export default AdminDashboard;
