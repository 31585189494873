import React from 'react';
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid';
import NumberFormat from 'react-number-format';
import { Link } from 'gatsby';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Stat({ item }) {
  const increase = item.total >= 0;
  return (
    <div key={item.key} className="px-4 py-5 sm:p-6">
      <dt className="text-base font-medium text-gray-900">{item.title}</dt>
      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
        <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
          <NumberFormat value={item.total} displayType="text" thousandSeparator />
          <span className="ml-2 text-sm font-medium text-gray-500">from {' '} 
            <NumberFormat value={item.prior} displayType="text" thousandSeparator /></span>
        </div>

        <div
          className={classNames(
            increase >= 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
            'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-bold md:mt-2 lg:mt-0'
          )}
        >
          {increase ? (
            <ArrowSmUpIcon
              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          ) : (
            <ArrowSmDownIcon
              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}

          <span className="sr-only">{increase ? 'Increased' : 'Decreased'} by</span>
          <NumberFormat value={item.change} decimalScale={2} displayType="text" thousandSeparator suffix="%" />
        </div>
      </dd>
    </div>
  );
}

export default function StatsBar({ stats, title }) {
  if (!stats) {
    return null;
  }
  return (
    <div>
      <h3 className="text-lg leading-6 font-semibold text-gray-900">{title}</h3>
      <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
        {stats.map((item) => {
          const { href, key } = item;
          if (href) {
            return (
              <Link to={href} key={key}>
                <Stat item={item} />
              </Link>
            );
          }
          return <Stat item={item} key={key} />;
        })}
      </dl>
    </div>
  )
}